import React from 'react'

// styles
import './TermsAndConditions.css'

// react helmet
import { Helmet } from 'react-helmet-async';

export default function TermsAndConditions() {
  return (
    <div className="terms-and-conditions-container">
        <Helmet>
          <title>Terms and Conditions | Mantra Seeds</title>
          <meta
            name="description"
            content="Review our terms and conditions to understand the rules and guidelines for using our services and purchasing our products."
          />  
          <link rel="canonical" href="https://mantra-seeds.com/privacy-policy" />      
        </Helmet>
        <h1>Terms and Conditions</h1>
        <div className="terms-and-conditions-content">
          <h2>1. Acceptance of Terms</h2>
          <p>These Terms and Conditions ("Terms") govern your access to and use of the services and Mantra Seeds’ website (the "Services"), and any information, text, graphics, or other materials appearing on the Services (the "Content"). By accessing or using the Services, you are agreeing to these Terms and entering into a legally binding contract with Mantra Seeds.</p>
          <h2>2. Age Restrictions and Legal Compliance</h2>
          <p>Our services and products are available for purchase only within the United States. To use our services and purchase our products, you must be at least 21 years of age. By using our services, you represent and warrant that you are at least 21 years of age, that you reside in the United States, and that your use of the services does not violate any applicable law or regulation. Your accounts may be deleted without warning if we believe that you are under 21 years of age or do not reside in the United States.</p>
          <h2>3. Product Usage</h2>
          <p>The cannabis seeds sold by Mantra Seeds are intended for personal use and must only be used in a manner consistent with the local, state, and federal laws of the jurisdiction in which you reside. The seeds are sold as adult novelty souvenirs, for genetic preservation purposes or as collectibles and are not intended for illegal use. We do not encourage or condone the illegal germination or cultivation of cannabis seeds where it is not permitted by law. We expressly point out that anyone purchasing our seeds is responsible for their future actions. By purchasing our seeds, you acknowledge that you will comply with all applicable laws in your jurisdiction.</p>
          <h2>4. Product Information and Availability</h2>
          <p>While we strive to keep our product descriptions accurate and current, there may be instances where product information on our Services may contain inaccuracies, omissions, or outdated information. Mantra Seeds reserves the right to correct any errors, inaccuracies, or omissions and to change or update information at any time without prior notice.</p>
          <h2>5. Prices and Payment</h2>
          <p>All prices on our website are displayed in the currency relevant to your selected location, and unless otherwise stated, are exclusive of sales tax and other applicable taxes. The total cost of your order will be the price of the products ordered plus the appropriate delivery charges. Payments for the products (including all applicable delivery charges) are processed securely through PayPal, or other payment processing services as made available. Upon completing your order, you will be directed to the PayPal site (or other payment processing service) to complete your purchase securely. We do not directly handle or store your credit card information. Please note that products and prices are subject to change. All prices on our website are applicable only to purchases made on the website. </p>
          <h2>6. Delivery, Ownership, and Risk</h2>
          <p>Delivery times given are estimates only. Ownership of the Products will pass to you once we have received payment in full for the Products and the Products have been delivered to you.</p>
          <h2>7. Returns and Refunds</h2>
          <p><strong>7.1 Return Policy:</strong> Our goal is your satisfaction. If you are unsatisfied with your purchase, you can return it to us within 30 days of the delivery date. To be eligible for a return, your item must be unused, in the same condition that you received it, and in its original packaging. Returns that do not meet these criteria will not be accepted.</p>
          <p><strong>7.2 Non-Returnable Items:</strong> Please note that certain items may be exempt from being returned. These include perishable goods, gift cards, downloadable software products, and personal care items.</p>
          <p><strong>7.3 Refunds:</strong> Once we receive your returned item and inspect it, we will notify you of the approval or rejection of your refund. If approved, your refund will be processed, and a credit will be applied to your original payment method within a certain number of days.</p>
          <p><strong>7.4 Damaged or Incorrect Items:</strong> If you received a damaged item or an item different from what you ordered, please contact us immediately at mantrafam@protonmail.com, so we can resolve the issue.</p>
          <p><strong>7.5 Return Shipping:</strong> You will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable. We recommend using a trackable shipping service or purchasing shipping insurance for items of significant value.</p>
          <h2>8. Privacy and Data Collection</h2>
          <p>By using the Services, you agree that Mantra Seeds can use such data in accordance with our Privacy Policy. Our Privacy Policy is incorporated into these Terms by reference.</p>
          <h2>9. User Obligations and Conduct</h2>
          <p>You agree to use the Services only for purposes that are permitted by these Terms and any applicable law, regulation, or generally accepted practices or guidelines in the relevant jurisdictions.</p>
          <h2>10. Account Security</h2>
          <p>When you create an account with us, you must provide information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms. You are responsible for safeguarding the password that you use to access the Services and for any activities or actions under your password. You agree to notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>   
          <h2>11. Termination</h2>
          <p>We reserve the right to terminate or suspend your access to our Services, without prior notice, for any reason at our discretion, including but not limited to a breach of these Terms. Upon termination, your right to use the Services will immediately cease. We shall not be liable to you or any third party for any termination of your access to our Services.</p>
          <h2>12. Dispute Resolution</h2>
          <p>In the event of a dispute arising out of or in relation to these Terms, we encourage you to first contact us directly to seek a resolution. If we cannot resolve the dispute, it shall be referred to arbitration in accordance with the rules of the American Arbitration Association. The venue for such arbitration will be California. The determination of the arbitrator will be final and binding on the parties.</p>
          <h2>13. Intellectual Property</h2>
          <p>All the intellectual property rights in the Services and the Content, including but not limited to, designs, text, graphics, pictures, video, information, applications, software, music, sound and other files are owned by us or our licensors.</p>
          <h2>14. Limitation of Liability</h2>
          <p>In no event will Mantra Seeds, its directors, employees, partners, suppliers, or content providers be liable for any indirect, incidental, punitive, consequential, special, or exemplary damages of any kind.</p>
          <h2>15. Indemnity</h2>
          <p>You agree to defend, indemnify and hold harmless Mantra Seeds, its officers, directors, employees and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from your use of and access to the Services.</p>
          <h2>16. Governing Law and Jurisdiction</h2>
          <p>These Terms will be governed by and construed in accordance with the laws of the state of California, without regard to its conflict of laws rules. You agree to submit to the exclusive jurisdiction of the state or federal courts in California for any dispute related to these Terms.</p>
          <h2>17. Changes to the Terms</h2>
          <p>Mantra Seeds reserves the right to modify these Terms at any time. We will always post the most current version of these Terms on our website. If we make material changes, we will notify you. By continuing to use our Services after any changes become effective, you agree to those changes.</p>
          <h2>18. Contact Us</h2>
          <p>If you have any questions about these Terms, please contact us at mantrafam@protonmail.com.</p>
        </div>
    </div>
  )
}
